// TODO: Ask about <ConfirmTermModal> component. When is the component used?
import async from 'async';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import ImgArr from '../../../assets/images/arr-002.svg';
import { BookStyleAlias, SendNotificationsFor } from '../../../dorian-shared/types/book/book';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import { ApproveDocumentModal } from '../../ui/PageWrapper/ApproveDocumentModal/ApproveDocumentModal';
import { ConfirmSpinOffRevenueModal } from '../../ui/PageWrapper/ConfirmSpinOffRevenueModal';
import { ConfirmTermModal } from '../../ui/PageWrapper/ConfirmTermModal';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import { fetchAgreementForPrompt, getDocumentIdsForPrompt } from '../_shared/agreementForPromptUtils';
import { BookAssociation } from '../Book/BookAssociation/BookAssociation';
import { validateBookAssociation } from '../Book/BookAssociation/bookAssociationUtils';
import { getUserFullName } from '../Users';
import { BookStyleSelector } from './BookStyle/BookStyleSelector';
import { BookTags } from './BookTags/BookTags';
import { CompetitiveModeSettings } from './CompetitiveModeSettings/CompetitiveModeSettings';
import { PromptSelect } from './PromptSelect/PromptSelect';

const auth = new Auth();

export class AddBook extends Component {
  constructor(...args) {
    super(...args);
    this.user = auth.getUser();
    const {
      book,
      template,
      items,
      limits,
    } = this.props;

    const isExistingBook = Boolean(book?.id);

    const hasSpinOffRevenue = Boolean(book?.spinOffRevenue);
    const hasSpinOffRevenueShare = Boolean(book?.spinOffRevenueShare);

    this.modalHeaderRef = React.createRef();

    this.state = {
      validated: false,
      formError: null,
      books: isExistingBook && book.groupId
        ? items.find((el) => el.id === book.groupId)
        : items.find((el) => el.isDefault),
      title: isExistingBook ? book.title : `${getUserFullName(this.user)} Story`,
      description: isExistingBook ? book.description : '',
      descriptionValid: limits?.story_temlate_description_max
        && limits?.story_temlate_description_max.value,
      featured: isExistingBook ? !!book.featured : false,
      original: isExistingBook ? !!book.original : false,
      liveSessionStreaming: isExistingBook ? !!book.liveSessionStreaming : true,
      spinOffRevenue: isExistingBook ? !!book.spinOffRevenue : true,
      bookDisabled: isExistingBook ? !!book.disabled : true,
      promptActiveContest: isExistingBook ? !!book.promptActiveContest : false,
      promptTrending: isExistingBook ? !!book.promptTrending : false,
      promptPriority: isExistingBook ? book.promptPriority : 0,
      data: isExistingBook ? book.data && JSON.stringify(book.data, null, 2) : {},
      tags: isExistingBook && book.tags ? book.tags : [],
      titleEpisode: 'Episode 1',
      summary: 'Episode 1',
      genreId: null,
      wizardStep: null,
      dataError: null,
      templates: [],
      fixedPreselectedPromptId: template || null,
      formErrorLimit: null,
      episodeErrorLimit: null,
      episodeDescErrorLimit: null,
      loading: false,
      acceptedOriginal: false,
      newBook: null,
      streamingRevenueShare: isExistingBook && book.streamingRevenueShare
        ? book.streamingRevenueShare
        : 0,
      spinOffRevenueShare: hasSpinOffRevenue && hasSpinOffRevenueShare
        ? book.spinOffRevenueShare
        : 0,
      acceptedPromptRevenueShare: false,
      exclusiveAccess: isExistingBook ? !!book.exclusiveAccess : false,
      bookStyleId: isExistingBook ? book.bookStyleId : null,
      bookStyles: [],
      competitiveMode: isExistingBook ? !!book.competitiveMode : false,
      competitiveModeStreamingByNonCreators: isExistingBook
        ? !!book.competitiveModeStreamingByNonCreators
        : false,
      sendNotificationsFor: isExistingBook && book.sendNotificationsFor ? book.sendNotificationsFor : [],
    };
  }

  componentDidMount() {
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 3) {
      this.setState({
        wizardStep,
        title: this.user.email ? `${getUserFullName(this.user)} Story` : 'New Story',
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }
    this.loadData();
  }

  loadData() {
    const {
      type,
      book,
    } = this.props;

    this.setState({ loading: true });
    async.parallel({
      genres: (callback) => {
        api.get('/v1/genres')
          .then((res) => {
            callback(null, res.data.genres);
          }).catch((error) => {
            callback(error, null);
          });
      },
      prompts: (callback) => {
        api.get('/v1/bookprompts')
          .then((res) => {
            callback(null, res.data.prompts);
          }).catch((error) => {
            callback(error, null);
          });
      },
      bookStyles: (callback) => {
        api.get('/v1/bookstyles')
          .then((res) => {
            callback(null, res.data.styles);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      this.setState({ loading: false });
      if (err) {
        this.errorAlert(err);
      } else {
        const { prompts, genres, bookStyles } = res;
        prompts.sort((a, b) => b.promptPriority - a.promptPriority);

        const isExistingBook = Boolean(book?.id);
        if (!isExistingBook) {
          const dorianBookStyle = bookStyles.find(
            (bookStyle) => bookStyle.alias === BookStyleAlias.Dorian,
          );
          if (dorianBookStyle) {
            this.setState({ bookStyleId: dorianBookStyle.id });
          }
        }

        this.setState({
          templates: [{
            activeContest: false,
            description: null,
            disabled: false,
            id: null,
            title: 'No Story Prompt',
            trending: false,
            exclusiveAccess: false,
          }, ...prompts],
          genreId: genres[0]?.id,
          bookStyles,
        }, () => {
          if (type && (type === 'trending' || type === 'prompts')) {
            this.addBook(this.valueNoForm(), true);
          }
        });
      }
    });
  }

  valueNoForm = () => {
    const {
      title,
      featured,
      summary,
      titleEpisode,
      original,
      tags,
    } = this.state;

    return {
      featured,
      liveSessionStreaming: true,
      spinOffRevenue: false,
      original,
      summary,
      tags,
      title,
      titleEpisode,
    };
  };

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addBook(value, validated) {
    const {
      book,
      update,
    } = this.props;
    const {
      streamingRevenueShare,
      wizardStep,
      acceptedPromptRevenueShare,
      templates,
      books,
      fixedPreselectedPromptId,
      spinOffRevenueShare,
      competitiveMode,
      competitiveModeStreamingByNonCreators,
    } = this.state;

    if (validated === true) {
      this.setState({ loading: true });
      if (wizardStep === 3) {
        localStorage.setItem('wizardStep', wizardStep + 1);
      }

      if (book.id) {
        const data = {
          ...value,
          groupId: books.id,
          streamingRevenueShare,
          spinOffRevenueShare,
          competitiveMode,
          competitiveModeStreamingByNonCreators,
        };

        api.put(`/v1/books/${book.id}`, data)
          .then(() => {
            this.setState({
              loading: false,
            }, () => update());
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
            this.setState({
              loading: false,
            });
          });
      } else {
        if (fixedPreselectedPromptId) {
          // eslint-disable-next-line no-param-reassign
          value.promptData = {
            episodeTitle: value.titleEpisode,
            episodeSummary: value.summary,
          };
          const tmp = templates.find((template) => template.id === fixedPreselectedPromptId);
          // eslint-disable-next-line no-param-reassign
          value.prompt_uuid = tmp.uuid;

          if (!acceptedPromptRevenueShare) {
            this.setState({
              newBook: value,
            });
            return;
          }
        }

        const data = {
          ...value,
          competitiveMode,
          competitiveModeStreamingByNonCreators,
        };

        api.post('/v1/books', data)
          .then((res) => {
            if (res.data.documents) {
              localStorage.setItem('documents', JSON.stringify(res.data.documents));
              this.setState({
                newBook: value,
              });
            } else if (!value.prompt_uuid) {
              this.addStories(value, validated, res.data.book.id);
            } else {
              const { book: newBook } = res.data;
              if (newBook.stories && newBook.stories.length > 0) {
                window.location.assign(`/stories/${newBook.stories[0].id}/branches`);
              } else {
                window.location.assign(`/book/${newBook.id}`);
              }
              this.setState({
                loading: false,
                acceptedOriginal: false,
                newBook: null,
              });
            }
          })
          .catch((error) => {
            const agreements = error?.response?.data?.documents ?? [];
            if (agreements.length > 0) {
              this.setState({
                storyRelatedAgreementToAccept: agreements[0],
              });
            } else {
              this.errorAlert(error.response.data.error);
              this.setState({
                loading: false,
                acceptedOriginal: false,
                newBook: null,
              });
            }
          });
      }
    }
  }

  addStories(value, validated, id) {
    const {
      genreId,
      fixedPreselectedPromptId,
    } = this.state;

    if (validated === true) {
      this.setState({
        loading: true,
      });

      const valueStories = {
        title: value.titleEpisode,
        summary: value.summary,
        genreId,
      };
      if (fixedPreselectedPromptId) {
        valueStories.newTemplateId = fixedPreselectedPromptId;
      }
      api.post(`/v1/books/${id}/chapters`, valueStories)
        .then((res) => {
          window.location.assign(`/stories/${res.data.chapter.id}/branches`);
          this.setState({
            loading: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errorAlert(error.response.data.error);
          }
          this.setState({
            loading: false,
          });
        });
    }
  }

  handleSubmit(event) {
    const { limits, book } = this.props;
    const { bookStyleId, sendNotificationsFor } = this.state;

    event.preventDefault();
    const form = event.currentTarget;
    let validated = form.checkValidity();

    const serializedForm = serialize(form, { hash: true, empty: true, disabled: true });
    const isExistingBook = Boolean(book?.id);

    if (isExistingBook && this.user.role === 'admin') {
      const originalBookId = serializedForm.originalBookId || null;
      const sourceBookId = serializedForm.sourceBookId || null;
      const isPrompt = Boolean(serializedForm.isPrompt);

      const {
        isOriginalBookIdValid,
        isSourceBookIdValid,
        isPromptValid,
      } = validateBookAssociation(
        originalBookId,
        sourceBookId,
        isPrompt,
      );
      validated = isOriginalBookIdValid && isSourceBookIdValid && isPromptValid;
      if (!validated) {
        return;
      }
      // TODO: Ask Konstantin about this
      if (originalBookId !== book.originalBookId
          || sourceBookId !== book.sourceBookId
          || isPrompt !== book.isPrompt
      ) {
        const newData = {
          originalAssociation: {
            originalBookId: book.originalBookId,
            sourceBookId: book.sourceBookId,
            isPrompt: book.isPrompt,
          },
        };

        try {
          const serializedFormData = serializedForm.data
            ? JSON.parse(serializedForm.data)
            : {};

          const { originalAssociation } = serializedFormData;
          if (!originalAssociation) {
            serializedForm.data = JSON.stringify({ ...serializedFormData, ...newData });
          }
        } catch (e) {
          validated = false;
          this.setState({
            validated: false,
            dataError: e.message,
          });
        }
      }
    }

    if ('featured' in serializedForm) {
      serializedForm.featured = !!serializedForm.featured;
    }

    if ('original' in serializedForm) {
      serializedForm.original = !!serializedForm.original;
    }
    if ('liveSessionStreaming' in serializedForm) {
      serializedForm.liveSessionStreaming = !!serializedForm.liveSessionStreaming;
    }
    if ('spinOffRevenue' in serializedForm) {
      serializedForm.spinOffRevenue = !!serializedForm.spinOffRevenue;
    }

    if ('disabled' in serializedForm) {
      serializedForm.disabled = !!serializedForm.disabled;
    }

    if ('exclusiveAccess' in serializedForm) {
      serializedForm.exclusiveAccess = !!serializedForm.exclusiveAccess;
    }

    if ('promptTrending' in serializedForm) {
      serializedForm.promptTrending = !!serializedForm.promptTrending;
    }
    if ('promptActiveContest' in serializedForm) {
      serializedForm.promptActiveContest = !!serializedForm.promptActiveContest;
    }
    if (('sourceBookId' in serializedForm) && !serializedForm.sourceBookId) {
      serializedForm.sourceBookId = null;
    }
    if (('originalBookId' in serializedForm) && !serializedForm.originalBookId) {
      serializedForm.originalBookId = null;
    }

    if ('isPrompt' in serializedForm) {
      serializedForm.isPrompt = serializedForm.isPrompt === 'on';
    }

    if (serializedForm.title && serializedForm.title.length > Number(limits.book_title_max.value)) {
      validated = false;
      this.setState({ validated: false, formErrorLimit: true });
      event.stopPropagation();
    }

    if (serializedForm.titleEpisode
        && serializedForm.titleEpisode.length > Number(limits.story_title_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeErrorLimit: true });
      event.stopPropagation();
    }

    if (serializedForm.summary
        && serializedForm.summary.length > Number(limits.story_summary_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeDescErrorLimit: true });
      event.stopPropagation();
    }

    serializedForm.bookStyleId = bookStyleId;
    serializedForm.sendNotificationsFor = sendNotificationsFor;

    try {
      if (serializedForm.data) {
        serializedForm.data = JSON.parse(serializedForm.data);
      }
    } catch (e) {
      validated = false;
      this.setState({
        validated: false,
        dataError: e.message,
      });
    }

    if (validated === false) {
      event.stopPropagation();
      this.modalHeaderRef.current.scrollIntoView(true);
    } else {
      this.addBook(serializedForm, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  checkCompetitiveDisable() {
    const { book, user } = this.props;
    const isCompetitiveDisable = book.approved_count > 0;
    const disabledCompetitiveReason = 'Cannot change game type after it is published.';

    return {
      isCompetitiveDisable,
      disabledCompetitiveReason,
    };
  }

  render() {
    const {
      validated,
      episodeErrorLimit,
      promptActiveContest,
      dataError,
      episodeDescErrorLimit,
      data,
      formError,
      descriptionValid,
      featured,
      newBook,
      books,
      tags,
      bookDisabled,
      title,
      acceptedOriginal,
      description,
      loading,
      formErrorLimit,
      spinOffRevenueShare,
      promptTrending,
      promptPriority,
      liveSessionStreaming,
      original,
      summary,
      acceptedPromptRevenueShare,
      exclusiveAccess,
      titleEpisode,
      wizardStep,
      streamingRevenueShare,
      spinOffRevenue,
      fixedPreselectedPromptId,
      templates,
      storyRelatedAgreementToAccept,
      bookStyles,
      bookStyleId,
      competitiveMode,
      competitiveModeStreamingByNonCreators,
      sendNotificationsFor,
    } = this.state;

    const {
      update,
      user,
      disabled,
      limits,
      book,
      onHide,
      items,
      type,
      template,
      ...props
    } = this.props;

    let titleText;
    if (book.id) {
      titleText = 'Edit Story';
    } else if (fixedPreselectedPromptId) {
      titleText = 'Loading Story';
    } else {
      titleText = 'Create Story';
    }
    const selectedPrompt = templates.find((o) => o.id === fixedPreselectedPromptId);

    const { isCompetitiveDisable, disabledCompetitiveReason } = this.checkCompetitiveDisable();

    return (
      <>
        <Modal
          {...props}
          size={type && (type === 'trending' || type === 'prompts') ? 'sm' : 'lg'}
          backdrop="static"
          keyboard={(wizardStep === 3)}
          aria-labelledby="contained-modal-title-vcenter"
          onHide={onHide}
        >
          <Modal.Header
            closeButton={!(type && (type === 'trending' || type === 'prompts'))}
            ref={this.modalHeaderRef}
          >
            <Modal.Title>{titleText}</Modal.Title>
          </Modal.Header>

          {
            type && (type === 'trending' || type === 'prompts')
              ? (
                <Modal.Body>
                  {
                  loading
                  && (
                  <>
                    <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
                      <Alert variant="danger">
                        {formError}
                      </Alert>
                    </Col>
                    <div style={{ margin: '3em' }}>
                      <div className="boxSpinner">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </>
                  )
                }
                </Modal.Body>
              )
              : (
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(e) => this.handleSubmit(e)}
                >

                  <Modal.Body className="overflow-visible">

                    <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
                      <Alert variant="danger">
                        {formError}
                      </Alert>
                    </Col>

                    <Row className="align-items-center">
                      <Col>
                        {
                          (book.id && (user.role === 'admin'))
                            && (
                            <InputGroup className="my-2">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="web-visible-uuid">uuid:</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                disabled
                                type="text"
                                placeholder="uuid\\;"
                                defaultValue={book.uuid}
                                name="web-visible-uuid"
                              />
                            </InputGroup>
                            )
                        }
                      </Col>
                      <Col sm="auto" className="text-right">
                        {(user.role === 'admin')
                            && (
                            <BookStyleSelector
                              loading={loading}
                              bookStyles={bookStyles}
                              bookStyleId={bookStyleId}
                              onStyleSelected={
                                (newBookStileId) => this.setState({
                                  bookStyleId: newBookStileId,
                                })
                              }
                            />
                            )}
                      </Col>
                    </Row>

                    {book.id && user.role === 'admin' && (
                      <BookAssociation
                        book={book}
                        setLoading={(value) => this.setState({ loading: value })}
                      />
                    )}

                    <Form.Row className="mt-3">
                      <Form.Group
                        as={Col}
                        controlId="StoryTitle"
                      >
                        <Form.Label className="pt-2">Story Title</Form.Label>

                        <InputGroup
                          className={`${wizardStep === 3 ? 'wizardActive' : ''}`}
                        >
                          <Form.Control
                            required
                            type="text"
                            disabled={
                            PremiumIpDisabledEdit(user.role, book)
                            || PremiumIpDisabledApprovedEdit(this.user.role, book)
                          }
                            placeholder="Story Title"
                            value={title}
                            onChange={(e) => {
                              this.setState({
                                formErrorLimit:
                                  e.target?.value?.length > Number(limits.book_title_max.value),
                                title: e.target.value,
                              });
                            }}
                            className={title && title.length > Number(limits.book_title_max.value) ? 'text-limit' : null}
                            pattern="^([A-Za-z]|[0-9]|_|-| |[.!?,:;()&|[\]\/@]|'|\u0022)+$"
                            name="title"
                          />

                          {
                          (wizardStep === 3)
                          && (
                          <div className="sidebarArr">
                            <img src={ImgArr} alt="" />
                            <span>Please input the Story Title</span>
                          </div>
                          )
                        }

                          <InputGroup.Prepend
                            style={{ position: 'relative' }}
                          >
                            <Form.Text className="char-limit-info-box">
                              {Number(limits.book_title_max.value) - (title
                                ? title.length
                                : 0)}
                            </Form.Text>
                          </InputGroup.Prepend>

                          {book.id
                          && (
                          <DropdownButton
                            as={InputGroup.Append}
                            variant="outline-secondary"
                            title={books.title}
                            id="BooksGroup"
                          >
                            {
                              items.map((item, i) => (
                                <Dropdown.Item
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={i}
                                  eventKey={item.id}
                                  active={(item.id === books.id)}
                                  onClick={() => {
                                    this.setState({
                                      books: item,
                                    });
                                  }}
                                >
                                  {item.title}
                                </Dropdown.Item>
                              ))
                            }
                          </DropdownButton>
                          )}

                          <Form.Control.Feedback
                            type="invalid"
                            className={formErrorLimit ? 'd-block' : 'd-none'}
                          >
                            Title is too long.
                          </Form.Control.Feedback>

                          <Form.Control.Feedback type="invalid">
                            Please choose a valid Story title.
                          </Form.Control.Feedback>

                        </InputGroup>
                      </Form.Group>
                    </Form.Row>

                    {
                    (
                      (
                        (
                          (book
                          && ['owner'].includes(book.book_role))
                          || user.role === 'admin'
                        )
                        && book.id
                        && book.isPrompt
                      )
                      || (book
                        && !book.isPrompt
                        && book.id
                      )
                      || !book
                      || !book.id
                    )
                    && (
                    <Row>
                      <Form.Group
                        as={Col}
                        controlId="templateName"
                      >
                        <Form.Label>{book.isPrompt ? 'Story Prompt Description' : 'Story Description'}</Form.Label>
                        <Form.Control
                          as="textarea"
                          disabled={
                            loading
                            || PremiumIpDisabledEdit(user.role, book)
                            || PremiumIpDisabledApprovedEdit(this.user.role, book)
                          }
                          placeholder="Description"
                          defaultValue={description}
                          name="description"
                          onChange={(e) => {
                            this.setState({
                              description: e.target.value,
                              descriptionValid:
                                limits
                                && limits.story_temlate_description_max
                                && limits.story_temlate_description_max.value,
                            });
                          }}
                          className={(
                            description
                            && limits
                            && limits.story_temlate_description_max
                            && limits.story_temlate_description_max.value
                            // eslint-disable-next-line max-len
                            && description.length > Number(limits.story_temlate_description_max.value)
                              ? limits.story_temlate_description_max.value
                              : 0) ? 'text-limit' : ''}
                        />

                        <Form.Text className="char-limit-info-box">
                          {Number(
                            limits
                            && limits.story_temlate_description_max
                            && limits.story_temlate_description_max.value
                              ? limits.story_temlate_description_max.value
                              : 0,
                          ) - (description
                            ? description.length
                            : 0)}
                        </Form.Text>
                      </Form.Group>
                    </Row>
                    )
                  }
                    <BookTags
                      disabled={
                        PremiumIpDisabledEdit(user.role, book)
                        || PremiumIpDisabledApprovedEdit(this.user.role, book)
                      }
                      tags={tags || []}
                    />

                    {(!book || !book.id)
                    && (
                    <>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          controlId="StoriesTitle"
                        >
                          <Form.Label className="pt-3">First Episode Title</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="First Episode Title"
                            pattern="^([A-Za-z]|[0-9]|_|-| |[.!?,:;()&|[\]\/@]|'|\u0022)+$"
                            name="titleEpisode"
                            defaultValue={titleEpisode || ''}
                            className={titleEpisode && titleEpisode.length > Number(limits.story_title_max.value) ? 'text-limit' : null}
                            onChange={(e) => {
                              this.setState({
                                episodeErrorLimit: e.target.value
                                  && e.target.value.length > Number(limits.story_title_max.value),
                                titleEpisode: e.target.value,
                              });
                            }}
                          />
                          <Form.Text className="char-limit-info-box">
                            {Number(limits.story_title_max.value) - (titleEpisode
                              ? titleEpisode.length
                              : 0)}
                          </Form.Text>

                          <Form.Control.Feedback
                            type="invalid"
                            className={episodeErrorLimit ? 'd-block' : 'd-none'}
                          >
                            First Episode Title is too long.
                          </Form.Control.Feedback>

                          <Form.Control.Feedback type="invalid">
                            Please choose a title.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          controlId="StorySummary"
                        >
                          <Form.Label>First Episode Description</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows="3"
                            maxLength="280"
                            placeholder="First Episode Description"
                            name="summary"
                            isInvalid={validated && summary.match(/^\s*$/)}
                            value={summary || ''}
                            className={summary && summary.length > Number(limits.story_summary_max.value) ? 'text-limit' : null}
                            onChange={(e) => {
                              this.setState({
                                episodeDescErrorLimit:
                                  summary?.length > Number(limits.story_summary_max.value),
                                summary: e.target.value,
                              });
                            }}
                          />
                          <Form.Text className="char-limit-info-box">
                            {Number(limits.story_summary_max.value) - (summary
                              ? summary.length
                              : 0)}
                          </Form.Text>

                          <Form.Control.Feedback
                            type="invalid"
                            className={episodeDescErrorLimit ? 'd-block' : 'd-none'}
                          >
                            First Episode Description is too long.
                          </Form.Control.Feedback>

                          <Form.Control.Feedback type="invalid">
                            Please choose a description.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                        >
                          <Form.Label>Story Prompts</Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <PromptSelect
                        bookId={template}
                        prompts={templates}
                        selectedPromptId={fixedPreselectedPromptId}
                        onPromptSelect={(promptId) => this.setState(
                          { fixedPreselectedPromptId: promptId },
                        )}
                      />
                    </>
                    )}

                    {user.role === 'admin' && book.id && (
                      <Alert variant="light" className="my-3">
                        <Form.Row>
                          <Col md="auto" className="font-weight-bold">
                            Admin only:
                          </Col>
                          <Col className="mx-2">
                            <Form.Check
                              custom
                              id="featured"
                              type="checkbox"
                              label="Featured"
                              checked={featured}
                              onChange={(e) => {
                                this.setState({
                                  featured: e.target.checked,
                                });
                              }}
                            />
                          </Col>
                          <Col className="mx-2">
                            <Form.Check
                              custom
                              id="original"
                              type="checkbox"
                              label="Premium IP"
                              checked={original}
                              onChange={(e) => {
                                this.setState({
                                  original: e.target.checked,
                                });
                              }}
                            />
                          </Col>
                          <Col md="auto" className="mx-2">
                            <Form.Check
                              custom
                              id={`sendNotificationFor-${SendNotificationsFor.Achievement}`}
                              type="checkbox"
                              label="Achievement notification"
                              checked={sendNotificationsFor.includes(SendNotificationsFor.Achievement)}
                              onChange={(e) => {
                                if (e.target.checked && !sendNotificationsFor.includes(SendNotificationsFor.Achievement)) {
                                  this.setState({ sendNotificationsFor: [...sendNotificationsFor, SendNotificationsFor.Achievement] });
                                } else if (!e.target.checked && sendNotificationsFor.includes(SendNotificationsFor.Achievement)) {
                                  this.setState({ sendNotificationsFor: sendNotificationsFor.filter((item) => item !== SendNotificationsFor.Achievement) });
                                }
                              }}
                            />
                          </Col>
                        </Form.Row>
                      </Alert>
                    )}

                    {
                    (((book && ['owner', 'co-author'].includes(book.book_role)) || user.role === 'admin') && book.id)
                    && (
                    <Row>
                      <Col md={4}>
                        <InputGroup className="my-2">
                          <Form.Check
                            type="checkbox"
                            label="Allow live session streaming"
                            checked={liveSessionStreaming}
                            onChange={(e) => {
                              this.setState({
                                liveSessionStreaming: e.target.checked,
                              });
                            }}
                            disabled={
                                PremiumIpDisabledEdit(this.user.role, book)
                                || PremiumIpDisabledApprovedEdit(this.user.role, book)
                              }
                            name="liveSessionStreaming"
                          />
                        </InputGroup>
                      </Col>
                        {
                          liveSessionStreaming
                          && (
                          <Col md={8}>
                            <Form.Group>
                              <Form.Label className="pt-2 mb-0">
                                Your royalty % from streams of this story
                              </Form.Label>
                              <RangeSlider
                                min={0}
                                max={100}
                                step={5}
                                size="sm"
                                tooltip="on"
                                name="streamingRevenueShare"
                                tooltipLabel={(currentValue) => `${Math.round(currentValue)}%`}
                                value={(streamingRevenueShare * 100)}
                                onChange={(e) => {
                                  this.setState({
                                    streamingRevenueShare: e.target.value / 100,
                                  });
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Form.Group>
                          </Col>
                          )
                        }
                    </Row>
                    )
                  }
                    {
                    (((book && ['owner', 'co-author'].includes(book.book_role)) || user.role === 'admin') && book.id && book.sourceBookId && book.isPrompt)
                    && (
                    <Row>
                      <Col md={4}>
                        { (user.role === 'admin')
                            && (
                            <InputGroup className="my-2">
                              <Form.Check
                                type="checkbox"
                                label="Allow Spin-Off Revenue Share"
                                checked={spinOffRevenue}
                                onChange={(e) => {
                                  this.setState({
                                    spinOffRevenue: e.target.checked,
                                  });
                                }}
                                disabled={
                                  PremiumIpDisabledEdit(this.user.role, book)
                                  || PremiumIpDisabledApprovedEdit(this.user.role, book)
                                  || !book.isPrompt
                                }
                                name="spinOffRevenue"
                              />
                            </InputGroup>
                            )}
                      </Col>
                        {
                          spinOffRevenue
                          && (
                          <Col md={8}>
                            <Form.Group>
                              <Form.Label className="pt-2 mb-0">
                                Spin-Off Revenue Share Value
                              </Form.Label>
                              <RangeSlider
                                min={0}
                                max={100}
                                step={5}
                                size="sm"
                                disabled={!book.isPrompt}
                                tooltip="on"
                                name="spinOffRevenueShare"
                                tooltipLabel={(currentValue) => `${Math.round(currentValue)}%`}
                                value={(spinOffRevenueShare * 100)}
                                onChange={(e) => {
                                  this.setState({
                                    spinOffRevenueShare: e.target.value / 100,
                                  });
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Form.Group>
                          </Col>
                          )
                        }
                    </Row>
                    )
                  }

                    <CompetitiveModeSettings
                      competitiveMode={competitiveMode}
                      onChangeCompetitiveMode={(value) => this.setState({ competitiveMode: value })}
                      streamingByNonCreators={competitiveModeStreamingByNonCreators}
                      onChangeStreamingByNonCreators={
                        (value) => this.setState({ competitiveModeStreamingByNonCreators: value })
                      }
                      disabled={isCompetitiveDisable}
                      disabledReason={disabledCompetitiveReason}
                    />

                    {user.role === 'admin'
                    && book.id
                    && (
                    <Form.Group controlId="data">
                      <Form.Label>Data</Form.Label>
                      <Form.Control
                        as="textarea"
                        size="sm"
                        rows={6}
                        name="data"
                        defaultValue={data}
                        isInvalid={!!dataError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dataError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    )}
                    {
                    user.role === 'admin'
                    && book.id
                    && book.isPrompt
                    && (
                    <InputGroup className="my-2">
                      <Form.Check
                        type="checkbox"
                        label="Trending"
                        disabled={exclusiveAccess}
                        checked={promptTrending}
                        onChange={(e) => {
                          this.setState({
                            promptTrending: e.target.checked,
                          });
                        }}
                        name="promptTrending"
                      />
                    </InputGroup>
                    )
                  }
                    {user.role === 'admin'
                    && book.id
                    && book.isPrompt
                    && (
                    <InputGroup className="my-2">
                      <Form.Check
                        type="checkbox"
                        label="Active Contest"
                        disabled={exclusiveAccess}
                        checked={promptActiveContest}
                        onChange={(e) => {
                          this.setState({
                            promptActiveContest: e.target.checked,
                          });
                        }}
                        name="promptActiveContest"
                      />
                    </InputGroup>
                    )}
                    {user.role === 'admin'
                      && book.id
                      && book.isPrompt
                      && (
                      <InputGroup className="my-2">
                        <Form.Check
                          type="checkbox"
                          label="Exclusive access"
                          checked={exclusiveAccess}
                          onChange={(e) => {
                            this.setState({
                              exclusiveAccess: e.target.checked,
                              promptTrending: false,
                              promptActiveContest: false,
                            });
                          }}
                          disabled={
                            PremiumIpDisabledEdit(this.user.role, book)
                            || PremiumIpDisabledApprovedEdit(this.user.role, book)
                          }
                          name="exclusiveAccess"
                        />
                      </InputGroup>
                      )}
                    {
                    (user.role === 'admin' && book.id && book.isPrompt)
                    && (
                    <Form.Group controlId="data">
                      <Form.Label>Priority</Form.Label>
                      <Form.Control
                        type="numeric"
                        name="promptPriority"
                        defaultValue={promptPriority}
                      />
                    </Form.Group>
                    )
                  }
                    {
                    ((/* (book && ['owner'].includes(book.book_role)) || */ user.role === 'admin') && book.id && book.isPrompt)
                    && (
                    <InputGroup className="my-2">
                      <Form.Check
                        type="checkbox"
                        label="Disabled"
                        checked={bookDisabled}
                        onChange={(e) => {
                          this.setState({
                            bookDisabled: e.target.checked,
                          });
                        }}
                        disabled={
                          PremiumIpDisabledEdit(this.user.role, book)
                          || PremiumIpDisabledApprovedEdit(this.user.role, book)
                        }
                        name="disabled"
                      />
                    </InputGroup>
                    )
                  }

                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      type="reset"
                      variant="secondary"
                      onClick={() => onHide()}
                      disabled={(wizardStep === 3 || loading)}
                    >
                      Cancel
                    </Button>

                    <span className={`wizardBtnBox ${wizardStep === 3 ? 'wizardActive' : ''}`}>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={
                      loading
                      || (title && title.length > Number(limits.book_title_max.value))
                      || formErrorLimit
                      || episodeErrorLimit
                      || episodeDescErrorLimit
                      || (disabled && this.user.role !== 'admin')
                      || (description && description.length > Number(descriptionValid))
                      || PremiumIpDisabledEdit(this.user.role, book)
                      || PremiumIpDisabledApprovedEdit(this.user.role, book)
                    }
                      >
                        {
                        loading
                        && (
                        <Spinner
                          size="sm"
                          animation="border"
                        />
                        )
                      }
                        Save
                      </Button>

                      {
                      (wizardStep === 3)
                      && (
                      <div className="sidebarArr">
                        <img src={ImgArr} alt="" />
                        <span>click to continue</span>
                      </div>
                      )
                    }
                    </span>

                  </Modal.Footer>
                </Form>
              )
}
        </Modal>

        {storyRelatedAgreementToAccept && (
          <ApproveDocumentModal
            document={storyRelatedAgreementToAccept}
            onCancel={() => {
              this.setState({
                storyRelatedAgreementToAccept: null,
              }, () => {
                onHide();
              });
            }}
            onSuccess={() => {
              this.setState({
                newBook: null,
                storyRelatedAgreementToAccept: null,
              }, () => {
                this.addBook(this.valueNoForm(), true);
              });
            }}
            onError={() => {
              this.errorAlert('There was an issue with signing agreement. Please contact support.');
            }}
          />
        )}
        {
          (
            !!newBook
            && !(!!selectedPrompt.spinOffRevenueShare && !acceptedPromptRevenueShare)
            && !acceptedOriginal
          )
          && (
          <ConfirmTermModal
            auth={auth}
            close={() => {
              this.setState({
                newBook: null,
                loading: false,
              }, () => {
                onHide();
              });
            }}
            update={() => {
              const bookToAdd = type && (type === 'trending' || type === 'prompts')
                ? this.valueNoForm()
                : JSON.parse(JSON.stringify(newBook));
              this.setState({
                newBook: null,
                acceptedOriginal: true,
              }, () => {
                this.addBook(bookToAdd, true);
              });
            }}
          />
          )
        }
        {
            !!newBook
            && selectedPrompt
            && !acceptedPromptRevenueShare
            && (
            <ConfirmSpinOffRevenueModal
              auth={auth}
              onClose={() => {
                this.setState({
                  newBook: null,
                  loading: false,
                }, () => {
                  onHide();
                });
              }}
              onConfirm={() => {
                const bookToAdd = type && (type === 'trending' || type === 'prompts')
                  ? this.valueNoForm()
                  : JSON.parse(JSON.stringify(newBook));
                this.setState({
                  newBook: null,
                  acceptedPromptRevenueShare: true,
                }, () => {
                  if (selectedPrompt) {
                    const documentIdsForPrompt = getDocumentIdsForPrompt(selectedPrompt);
                    if (documentIdsForPrompt.length > 0) {
                      const onAgreementFetched = (document) => {
                        this.setState({ storyRelatedAgreementToAccept: document });
                      };
                      fetchAgreementForPrompt(documentIdsForPrompt, onAgreementFetched);
                    } else {
                      this.addBook(bookToAdd, true);
                    }
                  } else {
                    this.addBook(bookToAdd, true);
                  }
                });
              }}
              promptBook={selectedPrompt}
            />
            )
        }
      </>
    );
  }
}
